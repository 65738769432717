import React, { Component } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import logo from '../assets/logo_fa.png';
import Share from './Share';
import { Redirect } from 'react-router-dom';
import Hammer from 'react-hammerjs';

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectProgramme: false,
			redirectSwipeRight: false
		};
	}

	setRedirectProgramme = () => {
		this.setState({
			redirectProgramme: true
		});
	};

	setRedirectSwipeRight = () => {
		this.setState({
			redirectSwipeRight: true
		});
	};

	renderRedirectProgramme = () => {
		if (this.state.redirectProgramme) {
			return <Redirect exact to='/Programme' />;
		}
	};

	renderRedirectSwipeRight = () => {
		if (this.state.redirectSwipeRight) {
			return <Redirect exact to='/Programme' />;
		}
	};

	render() {
		return (
			<Hammer onSwipeLeft={this.setRedirectSwipeRight}>
				<div className='d-flex flex-column align-items-center'>
					{this.renderRedirectProgramme()}
					{this.renderRedirectSwipeRight()}
					<img src={logo} alt='Logo FiestAround' className='img-fluid' />
					<Dropdown className='d-flex justify-content-center mt-4 mb-4'>
						<DropdownToggle style={{ backgroundColor: '#d22222' }} onClick={this.setRedirectProgramme}>
							Fêtes de Bayonne!
						</DropdownToggle>
					</Dropdown>
					<Share />
					<div style={{ textAlign: 'center', fontSize: '12px' }} className='mb-5'>
						<p className='mt-3'>
							Pour être informé des mises à jour ou nous aider à améliorer l'application{' '}
							<a
								href='https://sibforms.com/serve/MUIEANa_fhHKVcteXhVPZ38uJMRa-dYCEhHUe3UiySrldnFSzPLxUtWdfIPiKznnNiPhArxlcmc4BsvK-9fpqiVwlelKtChS4veXvY2aS_YnE9MKIOGq8ZSN2q9Trpon1GDDtJNRRGhTGZcbZ-bO85nZcC03rTPRN1mJZLskq09YYrXT-tzhTF8wbqgxURV7w8AdAb7TsIkwmQ1r'
								target='_blank'
								rel='noopener noreferrer'
							>
								cliquez ici
							</a>
						</p>

						<p className='mt-2'>
							<strong>Mentions légales</strong>
						</p>
						<p className='mt-3 mb-3'>
							Si vous souhaitez nous féliciter, nous aider, nous encourager par vos commentaires positifs ou négatifs:
							<a href='mailto:contact@fiestaround.fr?Subject=fiestaround%20app'> contact@fiestaround.fr</a>
						</p>

						<p>Nous essayerons d’être le plus réactif possible (pendant les fêtes, on ne promet rien!! ;-) )</p>
						<p>
							Pour retrouver les mentions légales:
							<br />
							<a href='https://www.fiestaround.fr/mentions-legales/' target='_blank' rel='noopener noreferrer'>
								https://www.fiestaround.fr/mentions-legales/
							</a>
							<br /> merci!
						</p>
					</div>
				</div>
			</Hammer>
		);
	}
}

export default HomePage;
