import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import * as programmeData from '../fichier_json_bdd/programme_2019.json';

class ProgrammeJour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			currentDay: null
		};
	}

	componentDidMount() {
		const dayMatch = this.props.match.params.dayName;
		this.setState({
			currentDay: dayMatch
		});
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect exact to='/Programme' />;
		}
	};

	render() {
		const dayData = [];
		programmeData.data.map(day => {
			if (day.Jour === this.state.currentDay) {
				dayData.push(day);
			}
		});
		return (
			<div className='mt-3 mb-5' style={{ fontSize: '13px' }}>
				<h4 style={{ textAlign: 'center' }}>{this.state.currentDay}</h4>
				<div className='d-flex mb-2'>
					{this.renderRedirect()}
					<Button outline color='danger' onClick={this.setRedirect}>
						Retour
					</Button>
				</div>
				<div className='mb-5'>
					<Table bordered>
						<thead>
							<tr>
								<th>Début</th>
								<th>Fin</th>
								<th>Evénements</th>
								<th>Informations</th>
							</tr>
						</thead>
						<tbody>
							{dayData.map(data => (
								<tr>
									<td>{data.Debut}</td>
									<td>{data.Fin}</td>
									<td>{data.Evenement}</td>
									<td>
										<Link to={`/Evenement/${this.state.currentDay}/${data.Evenement}`}>Plus d'infos</Link>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}
}

export default ProgrammeJour;
