import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import Hammer from 'react-hammerjs';

class Programme extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			jours: ['Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
			redirectSwipeRight: false,
			redirectSwipeLeft: false
		};
	}
	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect exact to={`/Programme/${this.state.jours[0]}`} />;
		}
	};

	setRedirectSwipeRight = () => {
		this.setState({
			redirectSwipeRight: true
		});
	};

	setRedirectSwipeLeft = () => {
		this.setState({
			redirectSwipeLeft: true
		});
	};

	renderRedirectSwipeRight = () => {
		if (this.state.redirectSwipeRight) {
			return <Redirect exact to='/' />;
		}
	};

	renderRedirectSwipeLeft = () => {
		if (this.state.redirectSwipeLeft) {
			return <Redirect exacte to='/BarEtPena' />;
		}
	};

	render() {
		return (
			<Hammer onSwipeLeft={this.setRedirectSwipeLeft} onSwipeRight={this.setRedirectSwipeRight}>
				<div className='mt-3'>
					{this.renderRedirectSwipeRight()}
					{this.renderRedirectSwipeLeft()}
					<h4 style={{ textAlign: 'center' }}>Programme</h4>
					<ListGroup className='mt-2'>
						{this.renderRedirect()}
						{this.state.jours.map((jour, index) => (
							<div className='mt-2' key={index}>
								<Link to={`/Programme/${jour}`} style={{ textDecoration: 'none', color: 'black' }}>
									<ListGroupItem style={{ boxShadow: '0px 3px #88888812' }}>{jour}</ListGroupItem>
								</Link>
							</div>
						))}
					</ListGroup>
					<p className='mt-3' style={{ fontStyle: 'italic', fontSize: '14px' }}>
						Notre application n'étant pas l’application officielle, certains éléments du programme peuvent être amenés à
						évoluer.
					</p>
				</div>
			</Hammer>
		);
	}
}

export default Programme;
