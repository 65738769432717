import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Navbar } from 'reactstrap';
import HomePage from './components/HomePage';
import Programme from './components/Programme';
import ProgrammeJour from './components/ProgrammeJour';
import BarPena from './components/BarPena';
import Prevention from './components/Prevention';
import Carte from './components/Carte';
import BarPage from './components/BarPage';
import ConseilsFestivaliers from './components/ConseilsFestivaliers';
import PointsRepos from './components/PointsRepos';
import Evenement from './components/Evenement';
import './css/navBar.css';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}
}

const Scroll = withRouter(ScrollToTop);

class RouterFile extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getLocation() {
		let currentLocation = window.location.pathname;
		console.log(currentLocation);
	}

	render() {
		return (
			<div>
				<Router>
					<Navbar
						color='light'
						light
						expand='md'
						className='d-flex justify-content-around flex-nowrap fixed-bottom col-12 pt-3 pb-3 pr-2 pl-2 navigationBar'
					>
						<NavLink exact to='/' activeClassName='is_active' style={{ color: 'black', textDecoration: 'none' }}>
							Accueil
						</NavLink>
						<NavLink to='/Programme' activeClassName='is_active' style={{ color: 'black', textDecoration: 'none' }}>
							Programme
						</NavLink>
						<NavLink to='/BarEtPena' activeClassName='is_active' style={{ color: 'black', textDecoration: 'none' }}>
							Bars & Peñas
						</NavLink>
						<NavLink to='/Prevention' activeClassName='is_active' style={{ color: 'black', textDecoration: 'none' }}>
							Prévention
						</NavLink>
						<NavLink to='/Carte' activeClassName='is_active' style={{ color: 'black', textDecoration: 'none' }}>
							Carte
						</NavLink>
					</Navbar>
					<Scroll>
						<Switch>
							<Route exact path='/' component={HomePage} />
							<Route exact path='/Programme' component={Programme} />
							<Route exact path='/BarEtPena' component={BarPena} />
							<Route exact path='/Prevention' component={Prevention} />
							<Route exact path='/Carte' component={Carte} />
							<Route exact path='/Prevention/PointsRepos' component={PointsRepos} />
							<Route exact path='/Prevention/ConseilsFestivaliers' component={ConseilsFestivaliers} />
							<Route exact path='/Programme/:dayName' component={ProgrammeJour} />
							<Route exact path='/Evenement/:jour/:evenement' component={Evenement} />
							<Route exact path='/bars/:barName' component={BarPage} />
						</Switch>
					</Scroll>
				</Router>
			</div>
		);
	}
}

export default RouterFile;

// const RouterFile = () => {

// 	return (
// 		<div>
// 			<Router>
// 				<Navbar
// 					color='light'
// 					light
// 					expand='md'
// 					className='d-flex justify-content-around flex-nowrap fixed-bottom col-12 pt-3 pb-3 pr-2 pl-2 navigationBar'
// 					style={{ minWidth: '350px', overflow: 'auto' }}
// 				>
// 					<NavLink exact to='/' activeClassName='is_active' style={{ textDecoration: 'none' }}>
// 						Accueil
// 					</NavLink>
// 					<NavLink to='/Programme' activeClassName='is_active' style={{ textDecoration: 'none' }}>
// 						<img src={calendar} alt='home' style={{ width: '32px' }} />
// 					</NavLink>
// 					<NavLink to='/BarEtPena' activeClassName='is_active' style={{ textDecoration: 'none' }}>
// 						Bars & Peñas
// 					</NavLink>
// 					<NavLink to='/Prevention' activeClassName='is_active' style={{ textDecoration: 'none' }}>
// 						Prévention
// 					</NavLink>
// 					<NavLink to='/Carte' activeClassName='is_active' style={{ textDecoration: 'none' }}>
// 						Carte
// 					</NavLink>
// 				</Navbar>
// 				<Scroll>
// 					<Switch>
// 						<Route exact path='/' component={HomePage} />
// 						<Route exact path='/Programme' component={Programme} />
// 						<Route exact path='/BarEtPena' component={BarPena} />
// 						<Route exact path='/Prevention' component={Prevention} />
// 						<Route exact path='/Carte' component={Carte} />
// 						<Route exact path='/Prevention/PointsRepos' component={PointsRepos} />
// 						<Route exact path='/Prevention/ConseilsFestivaliers' component={ConseilsFestivaliers} />
// 						<Route exact path='/Programme/:dayName' component={ProgrammeJour} />
// 						<Route exact path='/Evenement/:jour/:evenement' component={Evenement} />
// 						<Route exact path='/bars/:barName' component={BarPage} />
// 					</Switch>
// 				</Scroll>
// 			</Router>
// 		</div>
// 	);
// };

// export default RouterFile;
