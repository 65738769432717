import React, { Component } from 'react';
import * as programmeData from '../fichier_json_bdd/programme_2019.json';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import IconEvent from '../icon_components/IconEvent';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';

class Evenement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			minZoom: 16,
			maxZoom: 18,
			zoom: 18,
			currentEvent: null,
			currentDay: null
		};
	}

	componentDidMount() {
		const dayMatch = this.props.location.pathname.split('/');
		const eventMatch = this.props.match.params.evenement;
		this.setState({
			currentEvent: eventMatch,
			currentDay: dayMatch[dayMatch.length - 2]
		});
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect exact to={`/Programme/${this.state.currentDay}`} />;
		}
	};

	render() {
		const dayMatch = this.props.location.pathname.split('/');
		const day = dayMatch[dayMatch.length - 2];
		const eventName = dayMatch[dayMatch.length - 1];
		console.log(eventName);
		return (
			<div className='mt-3 mb-5 d-flex flex-column align-items-center col-12 p-0'>
				<h4 style={{ textAlign: 'center' }}>{this.state.currentEvent}</h4>
				<ul>
					{programmeData.data.map(e =>
						e.Jour === day && e.Evenement === eventName ? (
							<ul className='d-flex flex-column justify-content-start p-0 m-0'>
								<li>
									<strong>Description</strong>: {e.Descriptif}
								</li>
								<li>
									<strong>Jour:</strong> {e.Jour}
								</li>
								<li>
									<strong>Début:</strong> {e.Debut}
								</li>
								<li>
									<strong>Fin:</strong> {e.Fin}
								</li>
								<li>
									<strong>Adresse:</strong> {e.Adresse} {e.CP} {e.Ville}
								</li>
							</ul>
						) : null
					)}
				</ul>
				{this.renderRedirect()}
				<div className='d-flex col-12'>
					<Button outline color='danger' onClick={this.setRedirect}>
						Retour
					</Button>
				</div>
				{programmeData.data.map(e =>
					e.Jour === day && e.Evenement === eventName ? (
						<Map center={[e.Latitude, e.Longitude]} zoom={this.state.zoom} className='mt-2 mb-5'>
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url='../../Tiles/{z}/{x}/{y}.png'
								minZoom={this.state.minZoom}
								maxZoom={this.state.maxZoom}
							/>
							<Marker position={[e.Latitude, e.Longitude]} icon={IconEvent}>
								<Popup>
									<p>{e.Evenement}</p>
								</Popup>
							</Marker>
						</Map>
					) : null
				)}
			</div>
		);
	}
}

export default Evenement;
