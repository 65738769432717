import React, { Component } from 'react';
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import * as reposData from '../fichier_json_bdd/points_repos.json';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import IconRepos from '../icon_components/IconRepos';

class Carte extends Component {
	constructor(props) {
		super(props);
		this.state = {
			minZoom: 16,
			maxZoom: 18,
			lat: 43.4909,
			lng: -1.4748999999999342,
			zoom: 16
		};
	}
	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect exact to='/Prevention' />;
		}
	};

	render() {
		const position = [this.state.lat, this.state.lng];
		delete L.Icon.Default.prototype._getIconUrl;

		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
			iconUrl: require('leaflet/dist/images/marker-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png')
		});

		return (
			<div className='mt-3 d-flex flex-column align-items-center'>
				<h4>Points Repos</h4>
				{this.renderRedirect()}
				<div className='d-flex col-11'>
					<Button outline color='danger' onClick={this.setRedirect}>
						Retour
					</Button>
				</div>
				<Map center={position} zoom={this.state.zoom} className='mb-5 mt-2'>
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url='../Tiles/{z}/{x}/{y}.png'
						minZoom={this.state.minZoom}
						maxZoom={this.state.maxZoom}
					/>
					{reposData.data.map(repos => (
						<Marker position={[repos.Latitude, repos.Longitude]} style={{ backgroundColor: 'green' }} icon={IconRepos}>
							<Popup>
								<p>Description: {repos.Description}</p>
							</Popup>
						</Marker>
					))}
				</Map>
			</div>
		);
	}
}

export default Carte;
