import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { ListGroup, ListGroupItem, Input } from 'reactstrap';
import * as barData from '../fichier_json_bdd/bar_et_pena.json';
import Hammer from 'react-hammerjs';

class BarPena extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			redirectSwipeRight: false,
			redirectSwipeLeft: false,
			redirectTop: false
		};
	}

	updateSearch(event) {
		this.setState({ search: event.target.value.substr(0, 20) });
	}

	setRedirectSwipeRight = () => {
		this.setState({
			redirectSwipeRight: true
		});
	};

	setRedirectSwipeLeft = () => {
		this.setState({
			redirectSwipeLeft: true
		});
	};

	setRedirectTop = () => {
		this.setState({
			redirectTop: true
		});
	};

	renderRedirectSwipeRight = () => {
		if (this.state.redirectSwipeRight) {
			return <Redirect exact to='/Programme' />;
		}
	};

	renderRedirectSwipeLeft = () => {
		if (this.state.redirectSwipeLeft) {
			return <Redirect exacte to='/Prevention' />;
		}
	};

	renderRedirectTop = () => {
		if (this.state.redirectTop) {
			return <Redirect exact to='/BarEtPena' />;
		}
	};

	render() {
		let filteredBar = barData.data.filter(barSearch => {
			return barSearch.Nom.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
		});
		const { buttonLabel } = this.props;
		return (
			<Hammer onSwipeLeft={this.setRedirectSwipeLeft} onSwipeRight={this.setRedirectSwipeRight}>
				<div className='mt-3 mb-5'>
					{this.renderRedirectSwipeRight()}
					{this.renderRedirectSwipeLeft()}
					{this.renderRedirectTop()}
					<h4 style={{ textAlign: 'center' }} id='anchorBarPena'>
						Bars & Peñas
					</h4>
					<Input
						type='text'
						value={this.state.search}
						onChange={this.updateSearch.bind(this)}
						className='col-12'
						placeholder='Recherche'
						style={{ backgroundColor: '#e3e3e3', zIndex: '2' }}
					/>
					<ListGroup className='mt-1'>
						{filteredBar.map((bar, index) => (
							<div className='mt-2' style={{ boxShadow: '0px 3px #88888812' }} key={index}>
								<Link to={`/bars/${bar.Nom}`} style={{ textDecoration: 'none', color: 'black' }}>
									<ListGroupItem>
										{buttonLabel} {bar.Nom}
									</ListGroupItem>
								</Link>
							</div>
						))}
					</ListGroup>
				</div>
			</Hammer>
		);
	}
}

export default BarPena;
