import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ListGroupItem } from 'reactstrap';

class conseils extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect exact to='/Prevention' />;
		}
	};

	render() {
		return (
			<div className='mt-3 d-flex flex-column align-items-center'>
				<h4>Conseils Festayres</h4>
				{this.renderRedirect()}
				<div className='d-flex flex-column col-12'>
					<div>
						<Button outline color='danger' onClick={this.setRedirect} className='mb-3'>
							Retour
						</Button>
					</div>
					<p>Quelques conseils utiles pour passer d'agréables fêtes:</p>
				</div>
				<ul className='mt-2 mb-5 col-12'>
					<ListGroupItem>N°1: Consommations excessives = Danger (pour toi et pour les autres).</ListGroupItem>
					<ListGroupItem>N°2: Un bon comportement, tu auras (pas de comportement violent et/ou sexiste).</ListGroupItem>
					<ListGroupItem>N°3: La ville qui t'accueille, tu respecteras.</ListGroupItem>
					<ListGroupItem>N°4: Un comportement respectueux de l'environnement, tu adopteras.</ListGroupItem>
					<ListGroupItem>N°5: Les personnes en difficulté, tu aideras.</ListGroupItem>
					<ListGroupItem>N°6: Partage, amusement, chant, danse, échange, tradition, tu profiteras.</ListGroupItem>
					<ListGroupItem>N°7: Non, c'est non.</ListGroupItem>
					<ListGroupItem>
						N°8: Respect, convivialité, entraide, amusement sont les valeurs que tu prôneras.
					</ListGroupItem>
					<ListGroupItem>N°9: Tes copains , tu les rinceras (comprenne qui pourra)</ListGroupItem>
				</ul>
			</div>
		);
	}
}

export default conseils;
