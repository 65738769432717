import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import * as preventionData from '../fichier_json_bdd/prevention.json';
import { Redirect, Link } from 'react-router-dom';
import Hammer from 'react-hammerjs';

class Prevention extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			redirectSwipeRight: false,
			redirectSwipeLeft: false
		};
	}

	setRedirectSwipeRight = () => {
		this.setState({
			redirectSwipeRight: true
		});
	};

	setRedirectSwipeLeft = () => {
		this.setState({
			redirectSwipeLeft: true
		});
	};

	renderRedirectSwipeRight = () => {
		if (this.state.redirectSwipeRight) {
			return <Redirect exact to='/BarEtPena' />;
		}
	};

	renderRedirectSwipeLeft = () => {
		if (this.state.redirectSwipeLeft) {
			return <Redirect exacte to='/Carte' />;
		}
	};

	render() {
		return (
			<Hammer onSwipeLeft={this.setRedirectSwipeLeft} onSwipeRight={this.setRedirectSwipeRight}>
				<div className='mt-3'>
					{this.renderRedirectSwipeRight()}
					{this.renderRedirectSwipeLeft()}
					<h4 style={{ textAlign: 'center' }}>Prévention</h4>
					<ListGroup className='mt-2'>
						<Link to={`/Prevention/ConseilsFestivaliers`} style={{ textDecoration: 'none', color: 'black' }}>
							<ListGroupItem style={{ boxShadow: '0px 3px #88888812' }}>Conseils Festayres</ListGroupItem>
						</Link>
						<Link to={`/Prevention/PointsRepos`} style={{ textDecoration: 'none', color: 'black' }} className='mt-2'>
							<ListGroupItem style={{ boxShadow: '0px 3px #88888812' }}>Points Repos </ListGroupItem>
						</Link>
					</ListGroup>
					<h5 className='mt-5 mb-2'> Numéros d'urgence:</h5>
					<ul style={{ fontSize: '18px' }}>
						{preventionData.data.map((info, index) => (
							<li key={index}>
								{this.props.buttonLabel}
								{info.Quoi} ✆ {info.Numero}
							</li>
						))}
					</ul>
				</div>
			</Hammer>
		);
	}
}

export default Prevention;
