import React, { Component } from 'react';
import logo_fa from '../assets/logo_fa.png';
import {
	FacebookShareButton,
	TwitterShareButton,
	PinterestShareButton,
	WhatsappShareButton,
	FacebookIcon,
	TwitterIcon,
	PinterestIcon,
	WhatsappIcon
} from 'react-share';

import '../css/Share.css';

class Share extends Component {
	render() {
		const shareUrl = 'https://app.fiestaround.fr';
		const title = "Fiestaround l'app !!!! ";

		return (
			<div className='Demo__container'>
				<div className='some-network'>
					<FacebookShareButton url={shareUrl} quote={title} className='share-button'>
						<FacebookIcon size={32} round />
					</FacebookShareButton>
				</div>

				<div className='some-network'>
					<TwitterShareButton url={shareUrl} title={title} className='share-button'>
						<TwitterIcon size={32} round />
					</TwitterShareButton>
				</div>

				<div className='some-network'>
					<WhatsappShareButton url={shareUrl} title={title} separator=':: ' className='share-button'>
						<WhatsappIcon size={32} round />
					</WhatsappShareButton>
				</div>

				<div className='some-network'>
					<PinterestShareButton
						url={String(window.location)}
						media={`${String(window.location)}/${logo_fa}`}
						windowWidth={1000}
						windowHeight={730}
						className='share-button'
					>
						<PinterestIcon size={32} round />
					</PinterestShareButton>
				</div>
			</div>
		);
	}
}

export default Share;
