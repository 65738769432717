import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import * as barData from '../fichier_json_bdd/bar_et_pena.json';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import IconBar from '../icon_components/IconBar';

class BarPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			minZoom: 16,
			maxZoom: 18,
			zoom: 18,
			currentBar: null
		};
	}

	componentDidMount() {
		const { barName } = this.props.match.params;
		this.setState({ currentBar: barData.data.find(bar => bar.Nom === barName) });
		console.log(this.props.match.params);
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect exact to='/BarEtPena' />;
		}
	};

	render() {
		const { currentBar } = this.state;
		delete L.Icon.Default.prototype._getIconUrl;
		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
			iconUrl: require('leaflet/dist/images/marker-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png')
		});
		return (
			<div>
				{this.renderRedirect()}
				{currentBar && (
					<div className='mt-3 d-flex flex-column align-items-center'>
						<h4>{currentBar.Nom}</h4>
						<p>{currentBar.Adresse}</p>
						<div className='d-flex col-12'>
							<Button outline color='danger' onClick={this.setRedirect}>
								Retour
							</Button>
						</div>
						<Map
							center={[
								parseFloat(currentBar.Coordonnees.split(',')[1]),
								parseFloat(currentBar.Coordonnees.split(',')[0])
							]}
							zoom={this.state.zoom}
							className='mt-2 mb-5'
						>
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url='../Tiles/{z}/{x}/{y}.png'
								minZoom={this.state.minZoom}
								maxZoom={this.state.maxZoom}
							/>
							<Marker
								position={[
									parseFloat(currentBar.Coordonnees.split(',')[1]),
									parseFloat(currentBar.Coordonnees.split(',')[0])
								]}
								icon={IconBar}
							>
								<Popup>
									<ul>
										<li>Nom: {currentBar.Nom}</li>
										<li>Adresse: {currentBar.Adresse}</li>
									</ul>
								</Popup>
							</Marker>
						</Map>
					</div>
				)}
			</div>
		);
	}
}

export default BarPage;
