import React, { Component } from 'react';
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import * as barData from '../fichier_json_bdd/bar_et_pena.json';
import '../css/Carte.css';
import IconBar from '../icon_components/IconBar';
import IconRepos from '../icon_components/IconRepos';
import IconDistri from '../icon_components/IconDistri';
import Switch from 'react-switch';
import { Redirect } from 'react-router-dom';
import Hammer from 'react-hammerjs';

class Carte extends Component {
	constructor(props) {
		super(props);
		this.state = {
			minZoom: 16,
			maxZoom: 18,
			lat: 43.4909,
			lng: -1.4748999999999342,
			zoom: 16,
			barChecked: false,
			banqueChecked: false,
			reposChecked: false,
			redirectSwipeRight: false
		};
	}

	updateCheckedBar() {
		this.setState({ barChecked: !this.state.barChecked });
	}

	updateCheckedBanque() {
		this.setState({ banqueChecked: !this.state.banqueChecked });
	}

	updateCheckedRepos() {
		this.setState({ reposChecked: !this.state.reposChecked });
	}

	setRedirectSwipeRight = () => {
		this.setState({
			redirectSwipeRight: true
		});
	};

	renderRedirectSwipeRight = () => {
		if (this.state.redirectSwipeRight) {
			return <Redirect exact to='/Prevention' />;
		}
	};

	render() {
		const position = [this.state.lat, this.state.lng];
		delete L.Icon.Default.prototype._getIconUrl;

		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
			iconUrl: require('leaflet/dist/images/marker-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png')
		});

		let filteredBars = barData.data.map(barSearch => {
			return barSearch;
		});

		let filteredRepos = barData.dataRepos.map(reposSearch => {
			return reposSearch;
		});

		let filteredBanque = barData.dataDistri.map(distriSearch => {
			return distriSearch;
		});

		return (
			<div>
				<div className='mt-3 mb-5 d-flex flex-column align-items-center'>
					<Hammer onSwipeRight={this.setRedirectSwipeRight}>
						<div>
							<h4 style={{ textAlign: 'center' }}>Carte</h4>
							<div className='d-flex flex-column mb-2 mt-3' style={{ fontSize: '18px' }}>
								{this.renderRedirectSwipeRight()}
								<div className='mb-2'>
									<Switch
										onChange={this.updateCheckedBar.bind(this)}
										checked={this.state.barChecked}
										onColor='#ff0000'
										className='react-switch mr-1'
									/>
									<span style={this.state.barChecked ? { color: 'red' } : { color: 'black' }}> Bars & Peñas</span>
								</div>
								<div className='mb-2'>
									<Switch
										onChange={this.updateCheckedBanque.bind(this)}
										checked={this.state.banqueChecked}
										onColor='#008000'
										className='react-switch mr-1'
									/>
									<span style={this.state.banqueChecked ? { color: '#008000' } : { color: 'black' }}>
										{' '}
										Distributeurs
									</span>
								</div>
								<div>
									<Switch
										onChange={this.updateCheckedRepos.bind(this)}
										checked={this.state.reposChecked}
										onColor='#0000ff'
										className='react-switch mr-1'
									/>
									<span style={this.state.reposChecked ? { color: '#0000ff' } : { color: 'black' }}> Points repos</span>
								</div>
							</div>
						</div>
					</Hammer>
					<Map center={position} zoom={this.state.zoom} className='mb-5 mt-0'>
						<TileLayer
							attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url='../Tiles/{z}/{x}/{y}.png'
							minZoom={this.state.minZoom}
							maxZoom={this.state.maxZoom}
						/>
						{this.state.barChecked
							? filteredBars.map(bar => (
									<Marker
										position={[parseFloat(bar.Coordonnees.split(',')[1]), parseFloat(bar.Coordonnees.split(',')[0])]}
										icon={IconBar}
									>
										<Popup>
											<ul>
												<li>nom: {bar.Nom}</li>
												<li>adresse: {bar.Adresse}</li>
											</ul>
										</Popup>
									</Marker>
							  ))
							: null}
						{this.state.banqueChecked
							? filteredBanque.map(distri => (
									<Marker
										position={[
											parseFloat(distri.Coordonnees.split(',')[1]),
											parseFloat(distri.Coordonnees.split(',')[0])
										]}
										icon={IconDistri}
									>
										<Popup>
											<ul>
												<li>{distri.Nom}</li>
												<li>{distri.Adresse}</li>
											</ul>
										</Popup>
									</Marker>
							  ))
							: null}
						{this.state.reposChecked
							? filteredRepos.map(repos => (
									<Marker position={[repos.Latitude, repos.Longitude]} icon={IconRepos}>
										<Popup>
											<p>{repos.Description}</p>
										</Popup>
									</Marker>
							  ))
							: null}
					</Map>
				</div>
			</div>
		);
	}
}

export default Carte;
